import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Papers from 'components/Papers';
import Contributions from 'components/Contributions';

const Activity: React.FC = () => (
  <Layout>
    <SEO title="Profile" />
    <Papers />
    <hr />
    <Contributions />
  </Layout>
);

export default Activity;
